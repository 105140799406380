@import "../../../../styles/variables.scss";

.assessment{
    border: 1px solid $dmc-color-6;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 40px;

    &:last-child{
        margin-bottom: 0;
    }

    .assessment-title{
        padding: 17px 15px 12px;
        border-bottom: 1px solid $dmc-color-6;
        position: relative;

        .start-date{
            font-family: $dmc-font-bold;
            font-size: 19px;
            line-height: 23px;
            color: $dmc-color-5;
            margin-top: 6px;
            padding-right: 45px;
        }

        .end-date{
            color: $dmc-color-13;
            padding-right: 45px;
        }
        
        .delete-dmc{
            display: inline-block;
            position: absolute;
            top: calc(50% - 15px);
            right: 15px;                   

            &:hover{
                cursor: pointer;
                
                .icon-idle{
                    display: none;
                }
                .icon-hover{
                    display: block;
                }
            }

            img{
                width: 25px; 
                height: 25px;
            }

            .icon-idle{
                display: block;                
            }
            .icon-hover{
                display: none;
            }
        }
    }

    .assessment-steps{
        border-bottom: 1px solid $dmc-color-6;
        padding: 10px 0 13px 15px;

        .assessment-step-title{
            font-size: 16px;
            line-height: 25px;
            font-family: $dmc-font-bold;
            color: $dmc-color-2;
            margin-top: 14px;
            margin-bottom: 4px;

            &:first-child{
                margin-top: 0;
            }
        }
        .assessment-answers-list{
            color: $dmc-color-13;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .assessment-results{
        text-align: center;
        padding: 15px 0;

        a{
            color: $dmc-color-3;
            font-family: $dmc-font-bold;
            text-decoration: underline $dmc-color-3;
        }
    }
}