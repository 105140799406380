@import "./variables.scss";
#dmcWidget {
    form {
        //#region input styling
        input[type="radio"] {
            opacity: 0;
        }

        input[type="radio"] + label:before {
            content: "";
            position: absolute;
            left: -39px;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            border: 1px solid $dmc-color-3;
            border-radius: 100%;
            background-color: $dmc-color-1;
            transition: all 0.3s ease;
        }

        input[type="radio"]:checked + label:before {
            content: "";
            position: absolute;
            left: -39px;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            border: 1px solid $dmc-color-3;
            border-radius: 100%;
            background-color: $dmc-color-3;
        }

        input[type="radio"]:checked + label {
            color: $dmc-color-3;
        }
        //#endregion
        
        .answer {
            position: relative;
            text-align: left;

            min-height: 70px;
            display: flex;
            align-items: center;

            &:focus{
                outline: none;
            }

            @media screen and (max-width: $screen-sm-max) {
                min-height: 54px;
                padding-left: 25px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }  
            
            input[type="radio"]:focus{
                & ~ label:before{
                    box-shadow: 0 0 2px 2px $dmc-color-focus;
                }
            }

            input[type="text"] {
                width: 60px;
            }

            label {
                width: 310px;
                padding: 2px 0;
                position: relative;
                margin: 0 0 0 40px;
                font-family: $dmc-font-bold;
                font-size: 14px;
                line-height: 20px;
                color: $dmc-color-5;
                transition: all 0.2s ease;

                @media screen and (max-width: $screen-sm-max) {
                    margin-left: 15px;
                    line-height: 21px;
                    width: calc(100% - 15px);
                }

                &:focus{
                    outline: 1px dashed $dmc-color-12
                }

                > div{
                    display: inline;
                }

                input[type="text"] {
                    color: $dmc-color-3;
                    width: 150px;
                    padding: 7px 10px;
                    margin-left: 15px;

                    &::placeholder{
                        font-size: 16px;
                        font-family: $dmc-font-regular;                        
                    }

                    &.inside-text {
                        width: 60px;

                        &:focus{
                            box-shadow: 0 0 2px 2px $dmc-color-focus;
                        }
                    }
                }
            }
        }
        //radio btn with icon
        .radio-with-icon {
            margin: 0 5px;
            width: 110px;
            height: 110px;
            padding: 11px 0 10px;
            border-radius: 4px;
            border: 1px solid $dmc-color-12;
            font: $dmc-font-bold;
            color: $dmc-color-5;
            font-size: 14px;
            transition: all 0.2s ease;

            &.filled {
                background-color: $dmc-color-6;
                border-color: $dmc-color-16;
            }

            .icon {
                margin-bottom: 5px;

                img {
                    width: 25px;
                    height: 25px;
                }
            }

            .selector {
                margin-bottom: 6px;
                position: relative;

                label {
                    width: 100%;
                    min-height: 58px;
                    margin: 0;
                    padding: 0 5px 26px;
                    line-height: 16px;
                    font-size: 14px;
                    line-height: 16px;
                    font-family: $dmc-font-bold;
                    color: $dmc-color-5;

                    &:before {
                        width: 20px;
                        height: 20px;
                        top: auto;
                        bottom: 0;
                        left: calc(50% - 10px);
                    }
                }

                input[type="radio"] {
                    position: absolute;
                    bottom: 0;
                    left: 50%;

                    &:checked + label:before {
                        width: 20px;
                        height: 20px;
                        top: auto;
                        bottom: 0;
                        left: calc(50% - 10px);
                    }
                }
            }
        }

        .sub-answer {
            display: none;
            position: absolute;
            bottom: 104px;
            height: 200px;

            &.show {
                display: block;
            }

            .life-stage-title {
                font-family: $dmc-font-bold;
                font-size: 16px;
                line-height: 19px;
                color: $dmc-color-5;
                margin-bottom: 12px;
            }

            .answer {
                padding: 3px 0;
                padding-left: 24px;
                margin: 8px;
                min-height: 54px;

                input[type="radio"] {
                    margin-top: 17px;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                label {
                    padding: 0;
                    margin: 0;

                    input {
                        margin: 0 5px;

                        @media screen and (max-width: $screen-sm-max) {
                            padding-bottom: 0;
                            border: none;
                            border-bottom: 1px dashed $dmc-color-17;
                        }
                    }
                }
            }
        }
    }}
