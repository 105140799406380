@import "../../../../../../styles/variables.scss";

#dmcWidget {
    .extendable-items-list {
        margin: 0 20px;

        @media screen and (max-width: $screen-sm-max) {
            margin: 0;
        }
    }
}
