@import './variables.scss';

#dmcWidget {
    #questionnaire-page {
        //min-height: 680px;
        position: relative;

        @media screen and (max-width: $screen-sm-max) {
            min-height: unset;
        }

        #scroll-top-questionnaire {
            position: absolute;
            top: -145px;
            left: 50%;

            @media screen and (max-width: $screen-xlg-min) {
                top: -95px
            }
        }

        #loaderShadow{
            position: absolute;
            width: 100%;
            height: 100%;
            filter: grayscale(65%) opacity(75%) blur(1px);
            z-index: 9999;
        }
    }

    #info-page {
        -webkit-animation: fadein 0.5s;
        /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.5s;
        /* Firefox < 16 */
        -ms-animation: fadein 0.5s;
        /* Internet Explorer */
        -o-animation: fadein 0.5s;
        /* Opera < 12.1 */
        animation: fadein 0.5s;
        height: 100%;
    }

    .components-wrapper {
        position: relative;

        .feedback-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 85%;
            padding: 0 30px 30px;
            min-height: 360px;

            @media screen and (max-width: $screen-sm-max) {
                justify-content: center;
                align-items: center;
                padding: 0 0 30px;
            }

            .title {
                max-width: unset;
                margin: 0 auto 11px;

                @media screen and (max-width: $screen-sm-max) {
                    font-size: 20px;
                    line-height: 24px;
                    margin: 64px 0 30px;
                }
            }

            .description {
                font-size: 19px;
                line-height: 23px;
                color: $dmc-color-5;
                max-width: 710px;

                @media screen and (max-width: $screen-sm-max) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }

            min-height: calc(60vh - 50px);
        }

        .info-container {
            padding: 0 30px 30px;
            min-height: 360px;
            display: flex;
            flex-direction: column;

            @media screen and (max-width: $screen-sm-max) {
                min-height: 225px;
                padding: 0 0 15px;
            }

            .description {
                max-width: 541px;
                font-size: 16px;
                line-height: 21px;
                color: $dmc-color-5;
                margin: auto;

                .options {
                    margin-top: 19px;

                    .options-title {
                        color: $dmc-color-5;
                        font-family: $dmc-font-bold;
                        font-size: 19px;
                        font-weight: bold;
                        letter-spacing: -0.36px;
                        line-height: 21px;
                        text-align: center;
                    }

                    .options-icons {
                        display: flex;
                        justify-content: space-evenly;
                        margin-top: 28px;

                        @supports (-ms-ime-align: auto) {
                            justify-content: space-around;
                        }

                        .icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .icon-image {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                background-color: $dmc-color-6;

                                &:before {
                                    content: "";
                                    display: none;
                                }


                                &.circle {
                                    border-radius: 50%;
                                    height: 50px;
                                    width: 50px;
                                }

                                &.square {
                                    border-radius: 4px;
                                    box-sizing: border-box;
                                    border: 1px solid;
                                    height: 136px;
                                    width: 136px;
                                    margin-right: 19px;
                                }
                            }

                            .icon-description {
                                font-size: 14px;
                                font-family: inherit;
                                font-family: inherit;
                                font-weight: inherit !important;
                                line-height: inherit;
                            }
                        }
                    }
                }
            }
        }

        .spacing.questionnaire {
            min-height: 100%;
            width: 100%;

            form {
                -webkit-animation: fadein 0.5s;
                /* Safari, Chrome and Opera > 12.1 */
                -moz-animation: fadein 0.5s;
                /* Firefox < 16 */
                -ms-animation: fadein 0.5s;
                /* Internet Explorer */
                -o-animation: fadein 0.5s;
                /* Opera < 12.1 */
                animation: fadein 0.5s;

                .info-container {
                    font-size: 16px;
                    line-height: 21px;
                    color: $dmc-color-5;
                }

                .questions-container {
                    margin: 40px 30px 104px 30px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    min-height: 350px;

                    @media screen and (max-width: $screen-sm-max) {
                        margin: 0;
                    }

                    &.highlight-question {
                        .question-subtitle {
                            font-family: $dmc-font-bold;
                            font-size: 25px;
                            line-height: 30px;
                            color: $dmc-color-3;
                            margin-bottom: 30px;
                            width: 100%;

                            @media screen and (max-width: $screen-sm-max) {
                                font-size: 20px;
                                line-height: 24px;
                                margin-bottom: 23px;
                            }
                        }
                    }

                    .question-title {
                        font-family: $dmc-font-bold;
                        font-size: 25px;
                        line-height: 30px;
                        color: $dmc-color-3;
                        margin-bottom: 30px;
                        width: 100%;

                        @media screen and (max-width: $screen-sm-max) {
                            font-size: 20px;
                            line-height: 24px;
                            margin-bottom: 23px;
                        }
                    }

                    .question-subtitle {
                        font-size: 14px;
                        margin-bottom: 30px;

                        @media screen and (max-width: $screen-sm-max) {
                            margin-bottom: 15px;
                        }
                    }

                    .likert-container+.radioButtonList,
                    .question-subtitle+.radioButtonList {
                        min-height: unset;
                        margin-top: 0
                    }

                    >.radioButtonList {
                        min-height: 280px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-top: 20px;

                        @media screen and (max-width: $screen-sm-max) {
                            margin-top: 0;
                            min-height: unset;
                            width: 100%;
                        }
                    }

                    //steps with both likert and radio button list options
                    .likert-radio {
                        .likertScale {
                            @media screen and (max-width: $screen-sm-max) {
                                margin-top: 30px !important;
                            }

                            .likertBand {
                                @media screen and (max-width: $screen-sm-max) {
                                    padding-top: 0;
                                    margin-bottom: 44px !important;
                                }
                            }
                        }

                        .radioButtonList {
                            display: inline-block;

                            @media screen and (max-width: $screen-sm-max) {
                                display: block
                            }
                        }
                    }
                }
            }
        }


        .bottom-container {
            width: 100%;
            position: relative;
            left: 0;
            bottom: 0;
            padding: 0 30px 30px;
            // margin-top: 135px;

            &.info,
            &.feedback {
                padding: 0 30px 30px 30px;

                @media screen and (max-width: $screen-sm-max) {
                    padding: 0 0 15px;
                }
            }

            @media screen and (max-width: $screen-sm-max) {
                padding: 0 0 15px;
            }

            .submition-message {
                color: $dmc-color-13;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                margin-bottom: 15px;
                opacity: 1;
                transition: all 1s ease;
                display: block !important;

                @media screen and (max-width: $screen-sm-max) {
                    margin-bottom: 11px;
                }

                &.hide {
                    opacity: 0;
                }
            }

            .buttons-row {
                display: flex;
                justify-content: center;

                @media screen and (max-width: $screen-sm-max) {
                    flex-direction: column;
                }

                button:disabled {
                    height: 48px;
                }

                button:enabled {
                    padding: 15px !important;
                    height: 48px;
                    line-height: 19px;

                    &:focus {
                        background-color: $dmc-color-20 !important;
                        border-color: $dmc-color-20 !important;
                        box-shadow: 0 0 2px 2px $dmc-color-focus;
                    }

                    &:hover {
                        background-color: $dmc-color-20 !important;
                        border-color: $dmc-color-20 !important;
                    }

                    &.filled {
                        &:focus {
                            background-color: $dmc-color-5 !important;
                            border-color: $dmc-color-5 !important;
                            box-shadow: 0 0 2px 2px $dmc-color-focus;
                        }

                        &:hover {
                            background-color: $dmc-color-5 !important;
                        }
                    }


                    &:first-child {
                        margin-right: 30px !important;

                        @media screen and (max-width: $screen-sm-max) {
                            display: none;
                        }
                    }

                    &:last-child {
                        display: none;

                        @media screen and (max-width: $screen-sm-max) {
                            display: block;
                            margin-top: 15px
                        }
                    }
                }

                button:disabled:hover {
                    cursor: default;
                }
            }
        }
    }

    //animation
    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @media only screen and (max-width: $screen-sm-max) {
        .error-message-container {
            .dmc-button {
                width: 100% !important;
            }
        }
    }

    .error-message-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .error-message {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        svg {
            margin-bottom: 5px;
        }

        .dmc-button {
            width: 346px;
            height: 49px;
            border: solid 1px #0067C6;
            border-radius: 4px;
            padding: 10px;
            font-size: 16px;
            font-family: $dmc-font-regular;
            font-weight: bold;

            &:hover {
                text-decoration: none;
            }
        }
    }
}