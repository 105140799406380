@import "@styles/variables.scss";

#dmcWidget {
    .questionnaire-note {
        margin-top: 10px;
        width: 100%;
        max-width: 907px;

        @media screen and (max-width: $screen-sm-max) {
            width: 100%;
            padding: 0 15px 15px
        }

        .button-container {
            display: flex;
            justify-content: flex-end;

            .add-note-button {
                width: 100%;
                max-width: 208px;
                border: 1px solid $dmc-color-3;
                font-size: 16px;
                font-weight: bold;
                line-height: 19px;

                &:hover {
                    color: $dmc-color-1;

                    svg,
                    g,
                    path {
                        fill: $dmc-color-1;
                    }
                }
            }
        }

        .note-container {
            border: 1px solid $dmc-color-4;
            border-radius: 5px;
            padding: 16px;

            .notes-title {
                text-align: left;
                font-size: 19px;
                font-weight: bold;
                color: $dmc-color-3;
                line-height: 34px;
            }
        }
    }
}