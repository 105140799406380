@import "@styles/variables.scss";

#dmcWidget {
    .export-assessment {
        width: 100%;
        @media screen and (min-width: $screen-md-min){
            width: $screen-sm-max;
        }
        svg {
            height: 60px;
            width: 60px;
            margin-bottom: 10px;
        }

        .export-assessment-icon {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .send-txt {
                color: #0067c6;
                font-size: 16px;
                width: 60px;
                font-family: $dmc-font-regular;
            }
            @media screen and (max-width: $screen-sm-max){
                flex-direction: row;
                justify-content: left;
                align-items: center;
    
                .send-txt {
                    width: unset;
                    font-family: $dmc-font-regular;
                    padding: 0 0 15px 15px;
                }
            }
        }

        .send-container {
            width: 100%;
            display: flex;
            justify-content: center;

            .export-assessment-send {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                .send-txt {
                    font-size: 16px;
                    font-family: $dmc-font-bold;
                    font-weight: bold;
                    margin-bottom: 9px;
                }

                .email {
                    height: 56px;
                    max-width: 347px;
                    width: 100%;
                    font-size: 20px;
                    margin-bottom: 17px;
                    padding: 10px;
                }

                .send-btn {
                    background-color: #459914;
                    color: white;
                    font-size: 19.2px;
                    line-height: 32px;
                    font-family: $dmc-font-bold;
                }
                @media screen and (max-width: $screen-sm-max) {
                    width: 95%;
                    .email {
                        max-width: unset;
                    }
                }
            }
        }

        .result-message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}