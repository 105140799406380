@import './variables.scss';

#dmcWidget {
    .title {
        margin: 30px 0;
        color: $dmc-color-3;
        font-family: $dmc-font-bold;
        font-size: 25px;

        @media screen and (max-width: 767px) {
            margin: 15px 0;
        }
    }

    .without-description {
        padding-bottom: 47px;

        @media screen and (max-width: $screen-sm-max) {
            padding-bottom: 0;
        }
    }

    .subtitle {
        margin-top: 13px;
    }

    #questionnaire-page,
    .modal-content {
        .likertScale {
            margin-top: 121px;
            margin-bottom: 20px;

            @media screen and (max-width: $screen-sm-max) {
                margin-top: 80px;
            }

            .likertBand {
                @media screen and (max-width: $screen-sm-max) {
                    margin-bottom: 92px;
                }

                .likertResponse {
                    @media screen and (max-width: $screen-sm-max) {
                        position: relative;
                    }

                    .likertText {
                        max-width: 100px;
                        margin: 0 auto;

                        @media screen and (max-width: $screen-sm-max) {
                            position: absolute;
                            transform: translateX(-50%);
                            max-width: unset;
                            width: max-content;
                            left: 50%
                        }
                    }

                    &:first-child .likertText {
                        @media screen and (max-width: $screen-sm-max) {
                            left: calc(50% - 9px);
                            text-align: left;
                            width: 107px;
                            transform: none;
                        }
                    }

                    &:last-child .likertText {
                        @media screen and (max-width: $screen-sm-max) {
                            right: calc(50% - 9px);
                            text-align: right;
                            width: 107px;
                            transform: none;
                            left: unset;
                        }
                    }
                }
            }

        }
    }

    .modal-content {
        .likertScale {
            margin-top: 0;

            @media screen and (max-width: $screen-sm-max) {
                margin-bottom: 56px;
            }

            .likertBand {
                @media screen and (max-width: $screen-sm-max) {
                    margin-bottom: 0;
                }

                .likertResponse {
                    .likertLine {
                        border-top: 20px solid $dmc-color-6 !important;
                    }

                    .likertIndicator {
                        width: 20px !important;
                        height: 20px !important;
                        border-radius: 10px;
                        border: 4px solid $dmc-color-6 !important;
                    }

                    &>input:checked+.likertIndicator {
                        border-radius: 13px;
                        width: 26px !important;
                        height: 26px !important;
                        top: -3px !important;
                    }
                }
            }
        }
    }

    .likert {
        &[tabindex="0"] {
            &:focus {
                outline: auto;
            }
        }
    }

    .likertScale {
        margin-top: 50px;
        width: 100%;

        &.isKeyboardUser .likertResponse:focus {
            outline: none
        }

        &.isKeyboardUser .likertResponse:focus input~.likertText {
            box-shadow: none;
        }

        &.isKeyboardUser .likertResponse>input~.likertText {
            box-shadow: none;
        }

        &.isKeyboardUser .likertResponse:focus input~.likertIndicator {
            border: 4px solid $dmc-color-5 !important;
        }

        legend {
            margin: 30px 0;
            color: $dmc-color-3;
            font-family: $dmc-font-bold;
            font-size: 25px;
            border: 0;

            @media screen and (max-width: 767px) {
                margin: 15px 0;
            }
        }

        .likertBand {
            .likertResponse {
                margin: 0;

                &:focus {
                    outline: none;
                }

                .likertLine {
                    margin-top: 0 !important;
                    border-top: 28px solid $dmc-color-6 !important;

                    @media screen and (max-width: $screen-sm-max) {
                        border-top: 20px solid $dmc-color-6 !important;
                    }
                }

                .likertText {
                    display: none;
                }

                .likertIndicator {
                    background-color: white !important;
                    border: 6px solid $dmc-color-6 !important;
                    width: 28px !important;
                    height: 28px !important;
                    border-radius: 14px;
                    cursor: pointer;

                    @media screen and (max-width: $screen-sm-max) {
                        width: 20px !important;
                        height: 20px !important;
                        border-radius: 10px;
                        border: 4px solid $dmc-color-6 !important;
                    }
                }

                &>input:checked+.likertIndicator {
                    background-color: $dmc-color-3 !important;
                    border: 4px solid $dmc-color-1 !important;
                    border-radius: 16px;
                    width: 32px !important;
                    height: 32px !important;
                    top: -2px !important;

                    @media screen and (max-width: $screen-sm-max) {
                        border-radius: 13px;
                        width: 26px !important;
                        height: 26px !important;
                        top: -3px !important;
                    }
                }

                &>input:checked+.likertIndicator+.likertText {
                    color: $dmc-color-3;
                }

                .likertText {
                    font-family: $dmc-font-bold;
                    font-size: 14px;
                    color: $dmc-color-5;
                    line-height: 17px;
                    padding-top: 30px;

                    @media screen and (max-width: $screen-sm-max) {
                        padding-top: 10px;
                    }
                }

                &:first-child .likertText,
                &:last-child .likertText {
                    display: block;
                }
            }
        }
    }
}