@import "../../../../../styles/variables.scss";

@import "@styles/variables.scss";

#dmcWidget {
    section {
        position: relative;
        margin: 40px 15px 0px 15px;
        padding: 15px;
        border-radius: 4px;
        box-sizing: border-box;
        border: 1px solid $dmc-color-6;

        &:first-of-type,
        &:last-of-type {
        border: none;
        }

        &.filled-section {
            background-color: $dmc-color-6;
            border: 1px solid $dmc-color-16;
        }

        &:first-of-type {
            margin: 0;
            padding: 0;
        }

        .section-anchor{
            position: absolute;
            top: -30px;

            @media screen and (max-width: $screen-lg-max +1){
                top: -15px;
            }

            &.webViewMode{
                position: absolute;
                top: -125px;
    
                @media screen and (max-width: $screen-lg-max +1){
                    top: -85px;
                }
            }
        }

        .section-icon {
            height: 40px;
            width: 40px;
            margin-bottom: 16px;
        }

        >.description {
            max-width: 425px;
            margin: 0 auto 40px;
            text-align: center;
            font-size: 16px;
            line-height: 19px;            
        }
    }
}