@import "@styles/variables.scss";

#dmcWidget {
    .expandable-item {
        background-color: white;
        text-align: center;
        border: 1px solid $dmc-color-6;
        padding: 16px 15px;
        padding-bottom: 0;
        border-radius: 5px;
        margin-bottom: 16px;

        .expandableItem-link {
            margin: 0 -15px;
            padding-top: 16px;
            border-top: 1px solid $dmc-color-6;
            font-size: 14px;
            line-height: 17px;

            @media screen and (max-width: $screen-sm-max) {
                border-top: none;
                padding-top: 0;
                margin: 0;
            }

            a {
                color: $dmc-color-3;
                text-decoration: underline;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.expanded {
            background-color: $dmc-color-32;
        }

        .expandableItem-header {
            margin-bottom: 18px;

            @media screen and (max-width: $screen-sm-max) {
                margin-bottom: 14px;
            }

            .expandableItem-icon {
                background-color: $dmc-color-21;
                margin: auto;
                margin-right: 10px;
                width: 50px;
                height: 50px;
                border-radius: 25px;

                @media screen and (max-width: $screen-sm-max) {
                    margin: 0 10px 0 0;
                }

                img {
                    padding: 12.5px;
                }
            }

            .header-row {
                display: flex;
                width: 100%;

                @media screen and (max-width: $screen-sm-max) {
                    display: block;
                }

                .expandableItem-title {
                    display: flex;
                    align-items: center;
                    width: 50%;
                    font-family: $dmc-font-bold;
                    font-size: 19px;
                    line-height: 23px;
                    text-align: left;
                    color: $dmc-color-5;

                    @media screen and (max-width: $screen-sm-max) {
                        font-size: 16px;
                        line-height: 19px;
                        width: 100%;
                    }

                    div {
                        display: inline-block;
                        max-width: 233px;
                    }
                }

                .likert {
                    width: 50%;
                    padding-top: 35px;

                    @media screen and (max-width: $screen-sm-max) {
                        width: 100%;
                        padding-top: 0;
                    }

                    &.green {
                        .likertScale {
                            .likertBand {
                                .likertLine.fill {
                                    border-color: $dmc-color-9 !important;
                                }

                                .likertIndicator.fill {
                                    border-color: $dmc-color-9 !important;
                                    background-color: $dmc-color-22 !important;
                                }

                                .likertText {
                                    background-color: $dmc-color-9;

                                    &::before {
                                        border-color: transparent transparent $dmc-color-9 transparent;
                                    }
                                }
                            }

                            .likertResponse>input:checked+.likertIndicator {
                                background-color: $dmc-color-23 !important;
                            }
                        }
                    }

                    &.amber {
                        .likertScale {
                            .likertBand {
                                .likertLine.fill {
                                    border-color: $dmc-color-10 !important;
                                }

                                .likertIndicator.fill {
                                    border-color: $dmc-color-10 !important;
                                    background-color: $dmc-color-24 !important;
                                }

                                .likertText {
                                    background-color: $dmc-color-10;

                                    &::before {
                                        border-color: transparent transparent $dmc-color-10 transparent;
                                    }
                                }
                            }

                            input:checked+.likertIndicator {
                                background-color: $dmc-color-25 !important;
                            }
                        }
                    }

                    &.red {
                        .likertScale {
                            .likertBand {
                                .likertLine.fill {
                                    border-color: $dmc-color-11 !important;
                                }

                                .likertIndicator.fill {
                                    border-color: $dmc-color-11 !important;
                                    background-color: $dmc-color-26 !important;
                                }

                                .likertText {
                                    background-color: $dmc-color-11;

                                    &::before {
                                        border-color: transparent transparent $dmc-color-11 transparent;
                                    }
                                }
                            }

                            input:checked+.likertIndicator {
                                background-color: $dmc-color-26;
                            }
                        }
                    }

                    .likertScale {
                        margin: 0;
                        margin-top: 8px;

                        .likertBand {
                            padding-top: 5px;

                            @media screen and (max-width: $screen-sm-max) {
                                margin-left: -18%;
                            }

                            &:first-child .likertText,
                            &:last-child .likertText {
                                display: none;
                            }

                            .likertText {
                                margin-top: 10px;
                                position: relative;
                                border-radius: 5px;
                                color: $dmc-color-5;
                                font-size: 14px;
                                font-family: $dmc-font-regular;
                                line-height: 16px;
                                padding: 5px 3px;

                                @media screen and (max-width: $screen-sm-max) {
                                    padding-top: 5px;
                                }

                                &::before {
                                    display: none;
                                    content: "";
                                    position: absolute;
                                    top: -15px;
                                    left: calc(50% - 10px);
                                    border-style: solid;
                                    border-width: 10px;
                                    display: block;
                                    height: 0;
                                    width: 0;
                                }
                            }

                            .likertLine {
                                border-top: 10px solid $dmc-color-6 !important;
                            }

                            .likertIndicator {
                                width: 10px !important;
                                height: 10px !important;
                                border-radius: 5px;
                                border: 2px solid $dmc-color-6 !important;
                            }

                            .likertResponse>input:checked+.likertIndicator {
                                border: none !important;
                                width: 18px !important;
                                height: 18px !important;
                                border-radius: 9px;
                                top: -4px !important
                            }

                            input:disabled+.likertIndicator:hover {
                                cursor: default;
                            }
                        }
                    }
                }
            }
        }

        .expandable-container {
            transition: all 0.3s ease-in-out;
            overflow: hidden;
            height: 0;
            display: none;

            &.expanded {
                height: auto;
                display: block;
            }

            &:focus {
                outline: 1px dashed $dmc-color-2;
            }

            .expandable-item {
                margin-bottom: 10px;
                padding: 32px 15px 15px;

                .expandableItem-title {
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    max-width: 222px;
                    margin: auto;

                    @media screen and (max-width: $screen-sm-max) {
                        font-size: 14px;
                        line-height: 19px;
                        max-width: unset;
                    }

                    > div{
                        @media screen and (max-width: $screen-sm-max){
                            display: block;
                            max-width: unset;
                            width: 100%;
                        }
                    }
                }

                .likert {
                    .likertScale {
                        margin-top: 12px;

                        .likertBand {
                            margin-left: 0;
                        }
                    }
                }

                .notes-list-container{
                    margin-bottom: 15px;
                }
            }
        }

        .expandable-row {
            border-top: 1px solid $dmc-color-6;
            margin: 0 -15px;
            padding: 8px 0;

            &:hover {
                background-color: $dmc-color-20;
            }

            .expand-arrow {
                padding: 0 !important;
                background: transparent;
                width: 25px;
                border-radius: 13px;

                &:focus {
                    box-shadow: 0 0 2px 2px $dmc-color-focus;
                }

                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 25px;
                    height: 25px;
                    border-radius: 13px;
                    background-color: $dmc-color-6;

                    @media screen and (max-width: $screen-sm-max) {
                        background-color: unset;
                    }

                    img {
                        width: 9px;
                        height: 13px;
                        transition: 0.3s all ease;

                        &:hover {
                            width: 12px;
                            height: 16px
                        }

                        &:focus {
                            outline: 1px dashed $dmc-color-12;
                            width: 12px;
                            height: 16px
                        }

                        &.open {
                            transform: rotateZ(-180deg);
                        }
                    }
                }
            }
        }
    }
}