@import "../../../../../styles/variables.scss";

#dmcWidget {
    .assessment-btn-list {
        display: flex;
        background-color: $dmc-color-27;
        //justify-content: space-between;
        justify-content: center;

        @media screen and (max-width: $screen-sm-max) {
            padding: 8px 15px;
            justify-content: space-around;
        }
    }
}