@import "@styles/variables.scss";

#dmcWidget {
    .modal {
        &.confirm-modal {
            .message-modal-dialog{
                .dmc-button{
                        background-color: $dmc-color-1;
                        border-color: $dmc-color-3;
                        color: $dmc-color-3;

                        &:hover{
                            background-color: $dmc-color-20;
                            border-color: $dmc-color-20;
                        }
                }
            }

            .modal-content {
                .modal-header {
                    background-color: $dmc-color-3;
                    font-family: $dmc-font-regular;
                    font-size: 24px;
                    line-height: 36px;
                    color: $dmc-color-1;
                    padding: 12px 24px;
                    text-align: left;
                    height: 60px;
                    display: flex;

                    &::after,
                    &::before {
                        content: none;
                    }

                    .modal-close-btn {
                        cursor: pointer;

                        svg {
                            height: 30px;
                            width: 30px;
                        }
                    }
                }

                .modal-body {
                    font-size: 17px;
                    padding: 40px 15px;
                    text-align: center;

                    .alert-icon {
                        width: 60px;
                        height: 60px;
                        margin-bottom: 13px;

                        @media screen and (max-width: $screen-sm-max) {
                            width: 48px;
                            height: 48px;
                            margin-bottom: 10px;
                        }
                    }

                    .confirm-title {
                        color: $dmc-color-3;
                        font-family: $dmc-font-bold;
                        margin-bottom: 13px;
                    }

                    .confirm-description {
                        margin-bottom: 13px;
                    }

                    .confirm-btn {
                        width: 100%;
                        padding: 0;

                        .first {
                            margin-bottom: 13px;

                            button {
                                background-color: $dmc-color-31 !important;
                                border: none !important;
                                color: $dmc-color-1 !important;
                            }
                        }

                        button {
                            padding: 14px 0;
                            font-size: 17px !important;
                            line-height: 25px !important;
                            background-color: $dmc-color-1 !important;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: $screen-sm-max) {
        .modal {
            .modal-content {
                height: 100%;
                max-height: 100%;

                .modal-body {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            .modal-dialog {
                top: unset;
                left: unset;
                -ms-transform: unset;
                transform: unset;
                max-width: 100% !important;
                max-height: 100%;
                height: 100%;
                display: block;
                padding: 0;
                margin: 0;
            }
        }
    }
}