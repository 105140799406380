@import "../../../styles/variables.scss";

#dmcWidget{
    .dmc-info{
        .background-effect {
            position: absolute;
            z-index: 0;
            overflow-x: hidden;
            width: 100%;
            height: 400px;        
            left: 0;
            top: 0;
    
            @media screen and (max-width: $screen-sm-max){
                height: 305px;
            }
    
            div{
                position: absolute;
                left: -25%;
                top: 0;
                z-index: -1;
                width: 150%;
                height: 400px;
                border-bottom-left-radius: 50%;
                border-bottom-right-radius: 50%;
                background-color: $dmc-color-6;
    
                @media screen and (max-width: $screen-sm-max){
                    height: 305px;
                    width: 200%;
                    left: -50%
                }
            }
        }

        .dmc-content{
            position: relative;
            z-index: 1;

            .title{
                color: $dmc-color-5;
                font-size: 30px;
                line-height: 43px;
                margin-bottom: 33px;
        
                @media screen and (max-width: $screen-sm-max){
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 20px;
                }
            }
            .dmc-progress{
                display: inline-block;
                margin: 0 auto;
            }
            .date{                
                margin-top: 27px;
                color: $dmc-color-13;
        
                @media screen and (max-width: $screen-sm-max){
                    font-family: $dmc-font-bold;
                    margin-top: 13px;
                    font-size: 10px;
                    line-height: 12px;
                }        
            }
        }
    }
    
    .dmc-options{
        margin-top: 82px;
        padding: 0 15px;
    
        @media screen and (max-width: $screen-sm-max){
            position: relative;
            margin-top: 25px;
        }

        button{
            padding: 15px 0 14px !important;
            border: none !important;

            &:hover{
                background-color: $dmc-color-5 !important;
            }
        }
    
        .link{
            position: relative;
            display: inline-block;
            margin: 0 auto;
            margin-top: 43px;
            font-family: $dmc-font-bold;
            line-height: 17px;
            color: $dmc-color-3;
            text-decoration: underline;
            text-decoration-color: $dmc-color-3;   
            
            &:hover{
                cursor: pointer;
            }
    
            @media screen and (max-width: $screen-sm-max){
                margin-top: 72px;
            }
        }
    }
}