$dmc-color-1: white;
$dmc-color-2: black;
$dmc-color-3: #0067c6;
$dmc-color-4: #d6d6d6;
$dmc-color-5: #17295e;
$dmc-color-6: #e6eff7;
$dmc-color-7: #4e5357;
$dmc-color-8: rgba(0, 0, 0, 0.12);
$dmc-color-9: #c0f4ea;
$dmc-color-10: #f9d89a;
$dmc-color-11: #f9c7be;
$dmc-color-12: #bcbec0;
$dmc-color-13: #626262;
$dmc-color-14: #f2f2f2;
$dmc-color-15: #767676;
$dmc-color-16: #90C3EA;
$dmc-color-17: #979797;
$dmc-color-18: #E5E5E5;
$dmc-color-19: #878787;
$dmc-color-20: #E5F2FF;
$dmc-color-21: #D0DFEB;
$dmc-color-22: rgb(0, 209, 175);
$dmc-color-23: #00D1AF;
$dmc-color-24: rgb(255, 186, 0);
$dmc-color-25: #FFBA00;
$dmc-color-26: #F9C7BE;
$dmc-color-27: rgba(230, 239, 247, 0.5);
$dmc-color-28: #F1F1F1;
$dmc-color-29: #17316B;
$dmc-color-30: #17295E;
$dmc-color-amber: #F9D79B;
$dmc-color-red: #F9C7BE;
$dmc-color-green: #C1F4EA;
$dmc-color-31: #459914;
$dmc-color-32: rgba(230,239,247,0.4);

$dmc-color-focus: #10285A;

@font-face {
    font-family: "dmc-font-regular";
    src: url("../fonts/CoastSans-Regular.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "dmc-font-headline";
    src: url("../fonts/CoastSansHeadline-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "dmc-font-bold";
    src: url("../fonts/CoastSans-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-display: swap;
}

$dmc-font-regular: "dmc-font-regular", Helvetica, Arial, Sans-Serif;
$dmc-font-headline: "dmc-font-headline", Helvetica, Arial, Sans-Serif;
$dmc-font-bold: "dmc-font-bold", Helvetica, Arial, Sans-Serif;

$screen-xs-min: 0px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xlg-min: 1199px;

$screen-xs-max: $screen-sm-min - 1;
$screen-sm-max: $screen-md-min - 1;
$screen-md-max: $screen-lg-min - 1;
$screen-lg-max: $screen-xlg-min - 1;