@import '@styles/variables.scss';

#dmcWidget {
    section {
        .app-header {
            .header-title {
                width: 653px;
                
                @media screen and (max-width: $screen-sm-max) {
                    width: 100%;
                }

                h1 {
                    @media screen and (max-width: $screen-sm-max) {
                        font-size: 22px;
                        display: flex;
                    }
                }
            }
        }
    }

    .app-header {
        max-height: 245px;
        overflow: hidden;
        position: relative;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-bottom: 30px;

        @media screen and (max-width: 767px) {
            border-radius: 0;
        }

        img {
            width: 100%;

            @media screen and (max-width: $screen-sm-max) {
                min-height: 140px;
                object-fit: cover;
            }

            &.desktop {
                display: block;

                @media screen and (max-width: $screen-sm-max) {
                    display: none;
                }
            }

            &.mobile {
                display: none;

                @media screen and (max-width: $screen-sm-max) {
                    display: block;
                }
            }
        }

        .header-title {
            position: absolute;
            font-family: $dmc-font-headline;
            top: 50%;
            left: 50%;
            width: 90%;
            transform: translate(-50%, -50%);

            h1 {
                font-family: $dmc-font-headline;
                font-size: 36px;
                line-height: 48px;
                letter-spacing: 0;
                color: $dmc-color-1;

                @media screen and (max-width: $screen-xs-max) {
                    font-size: 20px;
                    line-height: 21px;
                }

                @media screen and (min-width: $screen-xs-max + 1) and (max-width: $screen-sm-max) {
                    font-size: 24px;
                    line-height: 27px;
                }
            }

            @media screen and (max-width: $screen-sm-max) {
                justify-content: center;
                align-items: center;
            }
        }
    }
}