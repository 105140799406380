@import "../../../styles/variables.scss";

#dmcWidget{
    .history-header-title{
        font-family: $dmc-font-bold;
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 28px;
        color: $dmc-color-3;
    }
    .assessment-list{
        padding-bottom: 20px;
        
        .title{
            font-size: 16px;
            font-family: $dmc-font-bold;
            line-height: 19px;
            margin-bottom: 30px;
            color: $dmc-color-5;
    
            &.sub{
                color: $dmc-color-13;
            }
        }
    }
}