@import "./variables.scss";

#dmcWidget {
  .small-title {
    font-family: $dmc-font-bold;
    font-size: 16px;
    line-height: 19px;
    color: $dmc-color-5;
    margin-bottom: 0;
    margin-top: 8px;

    @media screen and (max-width: $screen-sm-max) {
      font-family: $dmc-font-regular;
      font-size: 14px;
      line-height: 21px;
      margin-top: -13px;
    }
  }

  .goals-container {
    width: 100%;
    margin-top: 35px;

    @media screen and (max-width: $screen-sm-max) {
      margin-top: 10px;
    }
  }

  .goal {
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    margin-bottom: 15px;
    padding: 16px 23px 15px 21px;
    border: 1px dashed $dmc-color-12;
    border-radius: 5px;
    position: relative;
    cursor: grab;

    &.dragDashed {
      border: 1px dashed blue !important;
    }

    &.dragHover {
      border: 2px dashed green !important;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.filled {
      border: none;
      background-color: $dmc-color-6;

      .priority {
        background-color: $dmc-color-1;
        color: $dmc-color-5;
      }
    }

    &.empty {
      .priority-container .priority {
        color: $dmc-color-13;
        background-color: $dmc-color-14;
      }
    }

    &.column {
      width: 240px;
      min-height: 220px;
      margin: 0 10px;

      &:hover {
        cursor: default;
      }

      @media screen and (max-width: $screen-sm-max) {
        margin: 0;
        margin-bottom: 10px;
        min-width: 100%;
        min-height: unset;
        height: auto;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;

        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 0;
        }
      }

      .goal-column {
        width: 100%;
        // height: 100%;
        margin: 0 !important;
        text-align: center;
        flex-wrap: wrap;

        @media screen and (max-width: $screen-sm-max) {
          flex-wrap: unset;
        }

        >div {
          display: block;
          margin: auto;
        }

        .priority-container {
          @media screen and (max-width: $screen-sm-max) {
            padding-left: 0;
          }
        }

        .goal-data {
          width: 100%;
          padding: 0;
          text-align: center;
          margin-top: 15px;
          margin-bottom: 0;

          display: flex;
          flex-direction: column;
          align-content: end;
          justify-content: space-around;

          @media screen and (max-width: $screen-sm-max) {            
            margin-top: 0px;
            text-align: left;
          }

          .time {
            margin-top: 2px
          }

          .state {
            margin: auto;
            margin-top: 10px;

            @media screen and (max-width: $screen-sm-max) {
              margin-left: 0;
            }
          }

          .steps-to-take {
            margin-top: 20px;

            @media screen and (max-width: $screen-sm-max) {
              margin-top: 10px
            }
          }
        }
      }
    }

    .row {
      flex-wrap: unset;
    }

    .priority-container {
      display: inline-block;
      max-width: unset;
      padding: 0 15px;

      @media screen and (max-width: $screen-sm-max) {
        width: 84px;
        height: 54px;
      }

      .priority {
        background-color: $dmc-color-1;
        width: 54px;
        line-height: 54px;
        border-radius: 27px;
        color: $dmc-color-5;
        font-size: 35px;
        font-weight: bold;
      }
    }

    .goal-data {
      text-align: left;
      color: $dmc-color-5;
      padding-left: 3px;
      
      @media screen and (max-width: $screen-sm-max){
        padding-right: 36px;
      }

      .subject {
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
      }

      .time {
        font-size: 14px;
        line-height: 17px;
      }

      .state {
        padding: 0 8px;
        margin-top: 4px;
        border-radius: 4px;
        color: $dmc-color-5;
        font-family: $dmc-font-regular;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        padding: 3px 5px;

        &.success {
          background-color: $dmc-color-9;
          margin-left: 0;
          margin-bottom: 0;
        }

        &.warning {
          background-color: $dmc-color-10;
        }

        &.danger {
          background-color: $dmc-color-11;
        }
      }

      .steps-to-take a {
        color: $dmc-color-3;
        font-family: $dmc-font-bold;
        line-height: 17px;
        text-decoration: underline;
      }

      .edit-goal {
        position: absolute;
        right: 23px;
        top: calc(50% - 15px);
        cursor: pointer;

        &:hover,
        &:focus {
          outline: none;

          .idle {
            display: none;
          }

          .edit {
            display: block;
          }
        }

        @media screen and (max-width: $screen-sm-max) {
          padding-left: 0;
          right: 15px;
        }

        button {
          padding: 0 !important;
          background-color: transparent;
          width: auto;

          &.idle {
            display: block;
          }

          &.edit {
            display: none;
          }
        }
      }
    }

    .select-goal-container {
      padding-left: 3px;
      width: 50%;
      text-align: right;

      @media screen and (max-width: $screen-sm-max) {
        text-align: right;
        width: 50%;
      }

      .select-container {
        margin: 0;

        &:focus {
          outline: 1px dashed $dmc-color-2;

          .select-goal {
            color: $dmc-color-5
          }
        }

        img {
          width: 23px;
          height: 23px;
          margin-right: 9px;
        }

        .select-goal {
          transition: 0.2s all ease;
          display: inline-block;
          color: $dmc-color-3;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }

  legend {
    margin: 0 !important;
  }
}