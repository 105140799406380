@import "@styles/variables.scss";

#dmcWidget {
    .modal {
        &.confirm-modal.export-modal {
            position: fixed !important;

            @media screen and (max-width: $screen-sm-max){
                top: 0 !important;
                height: 100% !important;
                z-index: 10000;
            }

            .modal-dialog{
                @media screen and (max-width: $screen-sm-max){
                    width: 100%;
                }
            }
            
            .modal-content {
                @media screen and (max-width: $screen-sm-max){
                    height: 100vh;
                }

                .modal-header {
                    background-color: $dmc-color-3;
                    font-family: $dmc-font-regular;
                    font-size: 24px;
                    line-height: 36px;
                    color: $dmc-color-1;
                    padding: 12px 24px;
                    text-align: left;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &::after,
                    &::before {
                        content: none;
                    }

                    @media screen and (max-width: $screen-sm-max) {
                        font-size: 20px;
                        margin-top: 0;
                    }

                    .modal-close-btn {
                        cursor: pointer;
                        margin-left: 30px;

                        svg {
                            height: 30px;
                            width: 30px;
                        }

                        @media screen and (max-width: $screen-sm-max) {
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }

                .modal-body {
                    font-size: 17px;
                    padding: 40px 15px;
                    text-align: center;
                    
                    @media screen and (min-width: $screen-md-min) {
                        min-height: 190px;
                    }

                    .alert-icon {
                        width: 60px;
                        height: 60px;
                        margin-bottom: 13px;

                        @media screen and (max-width: $screen-sm-max) {
                            width: 48px;
                            height: 48px;
                            margin-bottom: 10px;
                        }
                    }

                    .confirm-title {
                        color: $dmc-color-3;
                        font-family: $dmc-font-bold;
                        margin-bottom: 13px;
                    }

                    .confirm-description {
                        margin-bottom: 13px;
                    }

                    .confirm-btn {
                        width: 100%;
                        padding: 0;

                        .first {
                            margin-bottom: 13px;

                            button {
                                background-color: $dmc-color-31 !important;
                                border: none !important;
                                color: $dmc-color-1 !important;
                            }
                        }

                        button {
                            padding: 14px 0;
                            font-size: 17px !important;
                            line-height: 25px !important;
                            background-color: $dmc-color-1 !important;
                        }
                    }
                }
            }
        }
    }
}