@import './variables.scss';
#dmcWidget {
    .circular-progressbar {
        width: 204px;
        height: 204px;

        .CircularProgressbar-trail {
            stroke: $dmc-color-1;
        }

        @media screen and (max-width: $screen-sm-max){
            width: 114px;
            height: 114px;
        }

        .CircularProgressbar-trail{
            stroke: $dmc-color-1;

            @media screen and (max-width: $screen-sm-max){
                stroke-width: 5px;
            }
        }

        .CircularProgressbar-path{
            stroke: $dmc-color-5;
            stroke-linecap: unset;
            transition: 'stroke-dashoffset 0.5s ease 0s';

            @media screen and (max-width: $screen-sm-max){
                stroke-width: 5px;
            }
        }

        .CircularProgressbar-text{
            fill: $dmc-color-5 !important;
            font-family: $dmc-font-headline;
            font-size: 30px;
            line-height: 36px;
        }
    }

    .linear-progressbar {
        height: 20px;

        &.out {
            span {
                position: absolute;
                right: -35px;
                color: $dmc-color-5 !important;
                text-align: right;
            }
        }

        & > div {
            background-color: $dmc-color-6 !important;
            border-radius: 4px !important;

            div {
                background-color: $dmc-color-5 !important;
                position: relative;

                span {
                    padding: 2px 0 !important;
                    width: 35px;
                    height: 20px;
                    text-align: left;
                    font-size: 14px !important;
                    line-height: 16px;
                    font-weight: 500 !important;
                }
            }
        }
    }
}