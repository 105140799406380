@import "../../../../../../styles/variables.scss";

#dmcWidget {
    .summary-goals-list {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        @media screen and (max-width: $screen-sm-max) {
            margin-bottom: 0;
            flex-direction: column;
        }

        .goal .goal-column .state{
            font-weight: 600;
        }
    }
}
