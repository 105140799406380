@import "@styles/variables.scss";

#dmcWidget {
    .title {
        font-family: $dmc-font-bold;
        font-size: 25px;
        line-height: 30px;
        color: $dmc-color-3;

        @media screen and (max-width: $screen-sm-max) {
            font-size: 20px;
            line-height: 24px;
        }
    }
}
