@import "variables.scss";

#dmcWidget {
    .failure {
        padding: 20px;
        background-color: #bc4138;
        color: white;
        margin: 10px;
        border-radius: 4px;
        animation: fadein 0.3s;

        .close_btn {
            margin-left: 15px;
            color: white;
            font-weight: bold;
            float: right;
            font-size: 22px;
            line-height: 20px;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                color: black;
            }
        }
    }

    button {
        border: none;
        border-radius: 3px;
        outline-color: none;
        padding: 15px 0;
        width: 330px;
        cursor: pointer;

        &:hover {
            background: #d6d6d6;
        }
    }

    .success {
        padding: 20px;
        background-color: #309e4c;
        color: white;
        margin: 10px;
        border-radius: 4px;
        animation: fadein 0.3s;

        .close_btn {
            margin-left: 15px;
            color: white;
            font-weight: bold;
            float: right;
            font-size: 22px;
            line-height: 20px;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                color: black;
            }
        }
    }

    button {
        border: none;
        border-radius: 3px;
        outline-color: none;
        outline: none;
        font-family: $dmc-font-bold;
        padding: 15px 0;
        width: 330px;

        @media screen and (max-width: $screen-sm-max) {
            width: 100%;
        }
    }
    /*#region Loader - Fade in */
    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @-moz-keyframes fadein {
        /* Firefox */
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadein {
        /* Safari and Chrome */
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @-o-keyframes fadein {
        /* Opera */
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    /*#endregion */
}
