@import "../../../../../../styles/variables.scss";

#dmcWidget {
    .highlight-list {
        display: flex;
        justify-content: center;
        margin-bottom: 34px;
        margin-top: 40px;

        @media screen and (max-width: $screen-sm-max) {
            margin-bottom: 19px;
            margin-top: 20px;
        }

        a,
        a:hover {
            text-decoration: none;
            margin-right: 20px;

            @media screen and (max-width: $screen-sm-max) {
                margin-right: 10px;
            }

            &:last-child {
                margin-right: unset;
            }
        }
    }
}
