#dmcWidget {
    .toggle-switch-container {

        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 150px;
        width: 100%;

        .title {
            font-family: Roboto;
            font-size: 16.8px;
            letter-spacing: 0;
            line-height: 25.2px;
            color: #000;
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 46.79px;
            height: 26px;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            width: 52px;
            height: 26px;
            border: 1.5px solid rgba(0, 0, 0, 0.1);

        }

        .slider:before {
            position: absolute;
            content: "";
            height: 22px;
            width: 22px;
            left: 1px;
            bottom: 1px;
            background-color: #FFF;
            -webkit-transition: .4s;
            transition: .4s;
            box-sizing: border-box;
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.05);
        }

        input:checked+.slider {
            background-color: #0067C6;
        }

        input:focus+.slider {
            box-shadow: 0 0 1px #0067C6;
        }

        input:checked+.slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 15.5px;
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }
}