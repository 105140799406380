@import "@styles/variables.scss";

#dmcWidget {
    .notes-list-container {
        background-color: $dmc-color-1;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px 20px;

        .notes-title {
            text-align: left;
            color: $dmc-color-3;
            font-size: 16px;
            font-weight: bold;
        }

        .expand-panel {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover {
                cursor: pointer;
            }

            .button-container {
                display: flex;
                justify-content: flex-end;

                .add-note-button {
                    width: 100%;
                    max-width: 208px;
                    border: 1px solid $dmc-color-3;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 19px;

                    &:hover {
                        color: $dmc-color-1;

                        svg,
                        g,
                        path {
                            fill: $dmc-color-1;
                        }
                    }
                }
            }
        }
    }
}