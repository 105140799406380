@import 'variables.scss';

#dmcWidget {
    .highlights-list {
        margin: 33px 0;

        :first-child {
            margin: 0;
        }

        @media screen and (max-width: 767px) {
            margin: 17px 0 15px;
        }

        .highlight {
            display: inline-block;
            width: 232px;
            margin-right: 10px;
            padding: 23.5px 21px;
            text-align: center;
            border-radius: 4px;
            border: 1px solid $dmc-color-6;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 9px;
                padding: 7.5px 14px;
            }

            &:last-child {
                margin-right: 0;

                @media screen and (max-width: 767px) {
                    margin-bottom: 0;
                }
            }

            .row {
                height: 100%;

                @media screen and (max-width: $screen-sm-max) {
                    height: unset;
                }

                .icon-container {
                    display: table-cell;
                    vertical-align: middle;

                    @media screen and (min-width: $screen-md-min) {
                        margin: 0 !important;
                    }

                    @media screen and (max-width: $screen-sm-max) {
                        padding-left: 0;
                    }

                    img {
                        margin: auto;
                        height: 50px;
                        width: 50px;

                        @media screen and (max-width: 767px) {
                            height: 35px;
                            width: 35px;
                        }
                    }
                }

                .highlight-title {
                    margin-top: 12px !important;
                    padding: 0 10px;
                    font-family: $dmc-font-bold;
                    font-size: 14px;
                    line-height: 17px;
                    color: $dmc-color-5;
                    padding: 0;

                    @media screen and (max-width: $screen-sm-max) {
                        margin: auto !important;
                        text-align: left;
                    }

                    div {
                        max-width: 190px;

                        @media screen and (max-width: $screen-sm-max) {
                            max-width: unset;
                        }
                    }
                }
            }
        }
    }
}
