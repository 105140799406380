@import "../../../styles/variables.scss";

#dmcWidget{
    min-height: 500px;
    
    @media screen and (max-width: $screen-sm-max){
        min-height: calc(100vh - 75px);
    }

    #scroll-top-summary{
        position: absolute;
        top: -220px;
        left: 50%;

        @media screen and (max-width: $screen-xlg-min) {
            top: -143px
        }
    }

    .with-alert{
        position: relative;
        //padding-top: 57px;
    
        .app-header{
            border-radius: 0 !important;
        }
    }   
}