@import "./variables.scss";

.modal {
    &.confirm-modal {
        min-width: unset;
        .confirm-modal-dialog{
            @media screen and (max-width: $screen-sm-max){
                display: flex !important;
                height: auto !important;
                width: 90% !important;
                overflow-x: hidden;                
                position: absolute !important;
                top: 50% !important;
                left: 50% !important;
                -ms-transform: translate(-50%, -50%) !important;
                transform: translate(-50%, -50%) !important;
            }
        }

        .modal-content {
            @media screen and (max-width: $screen-sm-max){
                min-width: 100%;
            }
            .modal-header {
                background-color: $dmc-color-3;
                font-family: $dmc-font-regular;
                font-size: 24px;
                line-height: 36px;
                color: $dmc-color-1;
                padding: 12px 24px;
                text-align: left;

                &::after,
                &::before {
                    content: none;
                }
            }

            .modal-body {
                display: block;
                font-size: 17px;
                padding: 40px 15px;
                text-align: center;

                .alert-icon {
                    width: 60px;
                    height: 60px;
                    margin-bottom: 13px;

                    @media screen and (max-width: $screen-sm-max) {
                        width: 48px;
                        height: 48px;
                        margin-bottom: 10px;
                    }
                }

                .confirm-title {
                    color: $dmc-color-3;
                    font-family: $dmc-font-bold;
                    margin-bottom: 13px;
                }

                .confirm-description {
                    margin-bottom: 13px;
                }

                .confirm-btn {
                    width: 100%;
                    padding: 0;

                    .first {
                        margin-bottom: 13px;

                        button {
                            background-color: $dmc-color-31 !important;
                            border: none !important;
                            color: $dmc-color-1 !important;
                        }
                    }

                    button {
                        padding: 14px 0;
                        font-size: 17px !important;
                        line-height: 25px !important;
                        background-color: $dmc-color-1 !important;

                        &:focus {
                            box-shadow: 0 0 2px 2px $dmc-color-focus;
                        }
                    }
                }
            }
        }
    }
}