@import "../../../../../../styles/variables.scss";

#dmcWidget {
    .contact-container {
            padding: 15px;
            border-radius: 5px;
            font-size: 14px;
            margin: 0 15px;

            &.filled {
                background-color: $dmc-color-1;
            }

            &.with-border {
                border: 1px solid $dmc-color-6
            }

            .contact-title {
                color: $dmc-color-5;
                font-family: $dmc-font-bold;
                font-size: 19px;
                line-height: 25px;
                margin-bottom: 14px;
            }

            .contact-subTitle {
                font-family: $dmc-font-bold;
                color: $dmc-color-3;
                line-height: 17px;
                margin-bottom: 9px;
            }

            .contact-description {
                color: $dmc-color-2;
                line-height: 20px;
                margin-bottom: 20px;
            }
    }
}
