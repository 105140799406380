@import "../../../styles/variables.scss";

#dmcWidget{
    .error-container{
        width: 100%;
        margin-left: 12px;
        margin-right: 12px;
        
        .error-page{
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 600px;

            @media screen and (max-width: $screen-sm-max){
                min-height: 400px;
            }
    
            .error-container{
                display: inline-block;
                margin: 0;

                @media screen and (max-width: $screen-sm-max){
                    width: unset;
                }
    
                img{
                    margin-bottom: 20px;
                }
                
                .error-title{
                    font-family: $dmc-font-bold;
                }
                .error-description {
                    margin-bottom: 20px;
                }

                button{
                    &:hover{
                        background-color: $dmc-color-20 !important;
                        border-color: $dmc-color-20 !important;
                    }
                }
            }
        }
    }    
}