@import "./variables.scss";

#dmcWidget {
    &[data-platform="dcu_mobile"] {
        .modal {
            .modal-content {
                .modal-header {
                    margin-top: 0;
                }
            }
        }        
    }

    .goal-selector {
        font-size: 14px;
        max-width: 345px;
        margin: auto;

        @media screen and (max-width: $screen-sm-max){
            max-width: unset;
            width: 100%;
        }

        .my-goal-selected {
            display: inline-block;
            width: 345px;
            color: $dmc-color-3;
            background-color: $dmc-color-6;
            border: 1px solid $dmc-color-16;
            border-radius: 4px;
            padding: 11px 14px 12px;
            margin-bottom: 10px;
            text-align: left;

            @media screen and (max-width: $screen-sm-max) {
                display: flex;
                align-items: center;
                width: 100%;
            }

            div {
                display: inline-block;
            }

            .goal-icon {
                margin-right: 16px;
                line-height: 30px;
            }

            .goal-title {
                max-width: 55%;
                margin: auto;
                font-family: $dmc-font-bold;

                @media screen and (max-width: $screen-sm-max) {
                    margin: unset
                }
            }

            .change-goal-btn {
                float: right;
                border-radius: 4px;
                background-color: $dmc-color-3;
                color: $dmc-color-1;
                line-height: 16px;
                padding: 7px;

                @media screen and (max-width: $screen-sm-max) {
                    justify-self: end;
                    margin-left: auto;
                    align-self: center;
                    line-height: unset;
                }

                &:hover {
                    cursor: pointer;
                    background-color: $dmc-color-5;
                }

                &:focus {
                    background-color: $dmc-color-5;
                }
            }
        }

        .small-title {
            color: $dmc-color-5;
            font-family: $dmc-font-bold;
            font-size: 16px;
            line-height: 33px;
            margin-top: 0;
        }

        .likert-container{
            margin: 0 -30px;

            @media screen and (max-width: $screen-sm-max){
                margin: 0 -15px
            }

            .likertScale{
                padding-bottom: 44px;

                .likertBand{
                    position: relative;
                    margin: 0;
    
                    .likertResponse{
                        .likertText {                        
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            max-width: 100px;                        
                            width: max-content;     
                            margin: 0 auto; 
                            padding-top: 10px;                  
                        }

                        &:last-child input{
                            position: absolute;
                            left: 0;
                        }
        
                        &:first-child .likertText {
                            left: calc(50% - 9px);
                            text-align: left;
                            width: 107px;
                            transform: none;
                        }
                        
                        &:last-child .likertText {
                                right: calc(50% - 9px);
                                text-align: right;
                                width: 107px;
                                transform: none;
                                left: unset;
                        }
                    }
                }
            }
        }
    }

    .modal {
        position: absolute !important;
        padding-right: 0 !important;
        opacity: 1;
        width: inherit;

        &.goal-modal{
            top: 115px !important;
            height: calc(100% - 115px) !important;

            @media screen and (max-width: $screen-sm-max){
                top: 0 !important;
                height: 100vh !important;
                position: fixed !important;
                z-index: 10000;
            }

            .modal-dialog{
                margin: 0;
                height: 100%;

                @media screen and (max-width: $screen-sm-max){
                    height: unset;
                    position: absolute;
                    width: 800px;
                }

                .modal-content{
                    max-height: calc(100% - 115px);
                    
                    @media screen and (max-width: $screen-sm-max){
                        height: 100vh;
                        top: 0;
                        .body-wrapper {
                            width: 100%;
                            .edit-goal-wrapper {
                                width: 95%;
                                margin: 0 auto 0 auto;
                                border: 1px solid #eff4ef;
                                border-radius: 0.3rem;
                                padding: 0.75rem;
                            }
                        }
                    }                                       

                    .modal-header{
                        margin-top: 0;
                    }
                    .options-row{
                        justify-content: space-between;
                        max-width: 230px;
                        margin: auto;

                        .options{
                            margin: 0;
                            margin-bottom: 10px;
                        }
                    }
                    .alert-icon {
                        width: 60px;
                        height: 60px;
                        margin-bottom: 13px;
    
                        @media screen and (max-width: $screen-sm-max) {
                            width: 45px;
                            height: 45px;
                            margin-bottom: 10px;
                        }
                    }
    
                    .confirm-description {
                        margin-bottom: 13px;
                    }
                    .confirm-btn {
                        width: 100%;
                        padding: 0;
    
                        .first {
                            margin-bottom: 13px;
    
                            button {
                                background-color: $dmc-color-31 !important;
                                border: none !important;
                                color: $dmc-color-1 !important;
                            }
                        }
    
                        button {
                            padding: 14px 0;
                            font-size: 17px !important;
                            line-height: 25px !important;
                            background-color: $dmc-color-1 !important;
    
                            &:focus {
                                box-shadow: 0 0 2px 2px $dmc-color-focus;
                            }
                        }
                    }
    
                }                
            }            
        }

        &.webViewMode{
            top: 0 !important;
            height: 100% !important;
        }

        .modal-dialog {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 375px;
            overflow-x: hidden;

            @media screen and (min-width: $screen-md-min) {
                max-width: $screen-md-min;
            }

            @media screen and (max-width: 375px){
                max-width: 100%;
            }
        }

        .modal-dialog,
        .modal-content {
            @media screen and (max-width: $screen-sm-max) {
                max-height: 85vh;
            }
        }

        .modal-content {
            .modal-header {
                display: block;
                position: relative;
                padding: 15px 0 19px;
                border: none;
                font-size: 17px;
                line-height: 22px;
                color: $dmc-color-2;
                font-family: $dmc-font-bold;
                text-align: center;
                background-color: transparent;

                &::after,
                &::before {
                    content: none;
                }

                @media screen and (max-width: $screen-sm-max) {
                    margin-top: 75px;
                }

                p {
                    margin-bottom: 0;
                }

                button {
                    position: absolute;
                    top: 13px;
                    right: 17px;
                    color: $dmc-color-3;
                    background-color: transparent;
                    outline: none;
                    border: none;
                    padding: 0 !important;
                    width: auto;

                    &:hover {
                        cursor: pointer;
                    }

                    &:focus {
                        color: $dmc-color-5;
                        outline: 1px dashed $dmc-color-2 !important;
                    }

                    img {
                        width: 13px;
                        height: 13px;
                    }
                }
            }

            .modal-body {
                text-align: center;
                padding: 10px 15px;
                margin: 0;
                overflow-x: hidden;

                .title {
                    display: none;
                }

                select {
                    option:first-child {
                        display: none;
                    }
                }

                .options-row {
                    margin-top: 0;
                    padding-bottom: 0;

                    @media screen and (max-width: $screen-sm-max){
                        justify-content: center;
                        max-width: 235px;
                        margin: auto;

                        .options{
                            margin: 0  0 10px !important;
                        }
                    }
                }
            }

            .modal-footer {
                justify-content: center;
                border: none;

                >* {
                    margin: .25rem !important;
                }

                button {
                    background-color: $dmc-color-3;
                    color: $dmc-color-1;
                    font-size: 16px;
                    line-height: 19px;
                    font-family: $dmc-font-bold;
                    padding: 15px 0 14px;
                    border: 0;

                    &:enabled:hover {
                        background-color: $dmc-color-5 !important;
                        border: none !important
                    }

                    &:focus{
                        box-shadow: 0 0 2px 2px $dmc-color-focus
                    }

                    &:disabled {
                        background-color: $dmc-color-18 !important;
                        color: $dmc-color-19 !important;
                        cursor: default;
                        border: 0 !important;
                    }
                }
            }
        }
    }
}