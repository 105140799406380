@import "@styles/variables.scss";

#dmcWidget {
    .status-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            color: $dmc-color-29;
            font-size: 19px;
            font-style: italic;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 23px;
            text-align: center;
            margin-bottom: 15px;
        }

        .subtitle {
            margin-bottom: 15px;
            margin-top: unset;
            color: $dmc-color-29;
            font-size: 19px;
            font-style: italic;
            letter-spacing: 0;
            line-height: 23px;
            text-align: center;
            word-break: break-all;
        }

        .description {
            min-height: 23px;
            min-width: 165px;
            border-radius: 4px;
            padding: 0 10px;
            margin: 0 15px 15px;

            .text {
                color: $dmc-color-30;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0;
                text-align: center;
                margin-bottom: 0;
            }

            &.amber {
                background-color: $dmc-color-amber;
            }

            &.green {
                background-color: $dmc-color-green;
            }

            &.red {
                background-color: $dmc-color-red;
            }
        }
    }
}
