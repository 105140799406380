@import "variables.scss";

#dmcWidget {
    .card {
        border: 1px solid $dmc-color-12 !important;
        border-radius: 8px !important;
        font-family: $dmc-font-bold;
        font-size: 14px;
        line-height: 16px;
        width: 110px;
        min-height: 110px;
        padding: 10px 5px;
        margin: 0 5px 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: center;
        /* compatibility with other browsers */
        display: -ms-flexbox;
        display: -webkit-flex;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        -webkit-align-items: center;
        -ms-flex-align: center;
        background-color: $dmc-color-1;
        color: $dmc-color-5;

        &.filled {            
            background-color: $dmc-color-6;
            color: $dmc-color-3;
            border-color: $dmc-color-16 !important;
        }

        img {
            width: 25px;
            height: 25px;
            margin-bottom: -1px;
        }

        &:focus,  &:hover{
            background-color: $dmc-color-20;
            border-color: $dmc-color-20 !important;
            outline: none;
            cursor: pointer;
        }

        &.dragged {
            &:hover {
                border: 1px solid $dmc-color-12 !important;
                background-color: $dmc-color-1;
            }            
        }

        label{
            margin-bottom: 0;

            .card-title{
                min-height: 32px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;

                > div {
                    display: inline-block;
                }
            }

            > div:last-child{
                height: 20px;
            }
        }

        .radioContainer{
            display: inline-block;
            padding: 0;
            margin: 0;

            .checkmark{
                margin: 0;
                background-color: unset;
            }
        }

        input[type="radio"]:focus ~ .checkmark{ 
            box-shadow: 0 0 2px 1px $dmc-color-focus;            
        }
    }

    .card:nth-child(1) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
    }

    .card:nth-child(2) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
    }

    .card:nth-child(3) {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
    }
}
