@import "../../../../../../styles/variables.scss";

#dmcWidget {
    .assessment-btn {
        color: $dmc-color-3;
        font-family: $dmc-font-bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: unset;
        padding: 18px 5px 17px !important;
        width: 33%;

        &:active {
            outline: none;
            background-color: $dmc-color-6 !important;
        }

        &:hover {
            background-color: $dmc-color-20;
        }

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width: $screen-sm-max) {
            display: block;
            margin-right: 0;
        }

        img {
            height: 25px;
            max-width: 25px;
            margin-right: 8px;
        }

        div {
            font-size: 13px;
            text-transform: uppercase;
            line-height: 24px;
            font-family: $dmc-font-regular;
        }
    }

    svg {
        margin-right: 5px;

        @media screen and (max-width: $screen-sm-max) {
            margin-right: 0;
        }
    }
}