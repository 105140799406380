@import 'variables.scss';

#dmcWidget {    
    .app-header {
        max-height: 245px;
        overflow: hidden;
        position: relative;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        @media screen and (max-width: 767px) {
            border-radius: 0;
        }

        img {
            width: 100%;

            &.desktop {
                display: block;

                @media screen and (max-width: $screen-sm-max) {
                    display: none;
                }
            }

            &.mobile {
                display: none;

                @media screen and (max-width: $screen-sm-max) {
                    display: block;
                }
            }
        }

        .header-title {
            position: absolute;            
            top: 50%;
            left: 50%;
            width: 90%;
            transform: translate(-50%, -50%);            

            h1 {
                font-family: $dmc-font-headline;
                font-size: 36px;
                line-height: 48px;
                letter-spacing: 0;
                color: $dmc-color-1;

                @media screen and (max-width: $screen-sm-max) {
                    font-size: 24px;
                    line-height: 27px;
                }
            }            
        }
    }
}
