@import './variables.scss';

#dmcWidget {  
    .modal-content{
        .lifeStage-container .options-row{
            @media screen and (max-width: $screen-sm-max){
                justify-content: space-around !important;
            }
        }
    }

    .lifeStage-container {
        width: 100%;       

        .small-title {
            font-family: $dmc-font-bold;
            font-size: 16px;
            line-height: 19px;
            color: $dmc-color-5
        }

        .lifeStage-header {
            text-align: center;            

            .title{
                font-family: $dmc-font-bold;
                font-size: 25px;
                line-height: 30px;
                color: $dmc-color-3;
                margin-bottom: 30px;
                margin-top: 30px;

                @media screen and (max-width: $screen-sm-max) {
                    max-width: 298px;
                    font-size: 20px;
                    line-height: 24px;
                    margin: 30px auto 23px;
                }
            }

            .small-title{
                @media screen and (max-width: $screen-sm-max){
                    margin-top: 0;
                }
            }

            .lifeStage.title {
                max-width: 602px;
                line-height: 30px;
                margin-bottom: 20px;
                
                @media screen and (max-width: $screen-sm-max){
                    line-height: 24px;
                    margin-top: 21px
                }
            }
        }

        .options-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: row;
            position: relative;
            margin-top: 22px;

            @media screen and (min-width: 366px) and (max-width: $screen-sm-max) {
                justify-content: space-around;
                max-width: 350px;
                margin: 22px auto 0;
            }

            @media screen and (max-width: 365px){
                justify-content: center;
                max-width: unset;

                .options:nth-child(2n+1){
                    margin: 0 10px 10px 0 !important;
                }
            }

            //lifeStage sub questions open styles
            &.with-subquestion-open{
                padding-bottom: 175px;

                @media screen and (max-width: $screen-sm-max) {
                    padding-bottom: 160px;
                }

                &.with-other{
                    padding-bottom: 230px;

                    @media screen and (max-width: $screen-sm-max) {
                        padding-bottom: 205px;
                    }                    
                }
            }

            &.centered{
                margin-top: 152px;
                padding-bottom: 0;                

                @media screen and (max-width: $screen-sm-max){
                    justify-content: center;
                    margin-top: 78px;
                }

                .options{
                    margin: 0 5px 10px;
                }
            }

            .options{
                margin: 0 5px 10px;
            
                @media screen and (max-width: $screen-sm-max){
                    margin: 0 0 10px;
                }                

                &:first-child{
                    margin-left: 0;
                }
    
                &.last{
                    margin-right: 0;
                }

                .card{
                    margin: 0
                }
            }            

            label {
                width: 100% !important;
            }

            .sub-questions {
                display: none;
                position: absolute;
                width: 375px;
                bottom: 0;
                left: calc(100% - (375/2));
                text-align: center;

                @media screen and (max-width: $screen-sm-max) {
                    width: 100%;
                }

                &.show {
                    display: block;
                }

                .small-title {
                    margin-bottom: 12px;
                }

                .sub-answers {
                    text-align: center;

                    .other-input {
                        margin-bottom: 10px;
                        padding: 7px 10px;

                        &:focus{
                            box-shadow: 0 0 2px 2px $dmc-color-focus;
                        }

                        &::placeholder{
                            color: $dmc-color-2
                        }
                    }

                    .small-title{
                        @media screen and (max-width: $screen-sm-max) {
                            margin-top: 0
                        }    
                    }

                    .sub-options {
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .answer {
                            min-height: 54px;

                            @media screen and (max-width: $screen-sm-max) {
                                padding-left: 40px;
                            }

                            input[type="radio"] {
                                position: absolute;
                            }

                            input[type="text"] {
                                width: 60px
                            }

                            label {
                                max-width: 306px;
                                margin: 0;
                                margin-left: 40px;
                                position: relative;
                                font-family: $dmc-font-bold;
                                font-size: 14px;
                                line-height: 20px;
                                transition: all 0.2s ease;

                                @media screen and (max-width: $screen-sm-max) {
                                    margin: 0;
                                    line-height: 21px;
                                    width: 100%;
                                }

                                input[type="text"] {
                                    width: 100px;
                                    padding: 7px 10px;
                                    margin-left: 10px;
                                    color: $dmc-color-3;

                                    &.inside-text {
                                        width: 72px;
                                        margin: 0 10px;
                                    }

                                    @media screen and (max-width: $screen-sm-max) {
                                        border: 0;
                                        border-bottom: 1px dashed $dmc-color-5;
                                    }
                                }

                                .checkmark {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    input[type="radio"] {
        opacity: 0;

        @media screen and (max-width: $screen-sm-max) {
            position: absolute;
        }
    }

    input[type="radio"] + label:before {
        content: '';
        position: absolute;
        left: -39px;
        top: calc(50% - 12px);
        width: 24px;
        height: 24px;
        border: 1px solid $dmc-color-3;
        border-radius: 100%;
        background-color: $dmc-color-1;
        transition: all 0.3s ease;
    }

    input[type="radio"]:checked + label:before {
        content: '';
        position: absolute;
        left: -39px;
        top: calc(50% - 12px);
        width: 24px;
        height: 24px;
        border: 1px solid $dmc-color-3;
        border-radius: 100%;
        background-color: $dmc-color-3;
    }

    input[type="radio"]:checked + label {
        color: $dmc-color-3
    }
}
