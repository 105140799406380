@import 'variables.scss';


#dmcWidget {
    @import '~slick-carousel/slick/slick';
    @import '~slick-carousel/slick/slick-theme';

    .slick-initialized .slick-slide {
        display: block;
    }

    .carousel-row {
        margin: 0 !important;
    }

    .title-container {
        @media screen and (max-width: $screen-sm-max) {
            width: 100%;
        }

        .carousel-title {
            max-width: 102px;
            float: right;
            text-align: center;
            font-family: $dmc-font-regular;
            font-size: 16px;
            line-height: 19px;
            color: $dmc-color-5;
            margin-left: 12px;
            margin-bottom: 0;

            @media screen and (max-width: $screen-sm-max) {
                //font-family: $dmc-font-regular;
                max-width: none;
                float: unset;
                margin-left: 0;
            }
        }
    }

    .carousel-container {
        padding-left: 111px !important;
        width: 60%;

        @media screen and (max-width: $screen-sm-max) {
            width: 100%;
            padding: 0 !important;
            margin-bottom: 23px;
        }
    }


    .slick-slider {
        @media screen and (max-width: $screen-sm-max) {
            margin-top: 15px;
        }
    }

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;

        &:focus{
            border: 1px solid $dmc-color-focus;
        }
    }

    .slick-item-holder {
        padding: 0 15px;
    }

    .slick-item {
        height: 109px;
        //width: 329px;
        text-align: left;
        position: relative;
        padding: 21px 12px 20px 20px;

        @media screen and (max-width: $screen-sm-max) {
            padding: 28px 21px 27px 27px;
            border-radius: 4px;
            box-shadow: 0 0 2px 0 $dmc-color-8;
        }

        img {
            position: absolute;
            width: 24px;
            height: 24px;

            &.top-img {
                top: 0px;
                left: 0px;
                transform: rotateZ(180deg);

                @media screen and (max-width: $screen-sm-max) {
                    top: 7px;
                    left: 7px;
                }
            }

            &.bottom-img {
                bottom: 0px;
                right: 0px;

                @media screen and (max-width: $screen-sm-max) {
                    bottom: 7px;
                    right: 9px;
                }
            }
        }

        .quote-content {
            position: absolute;
            width: 80%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
            line-height: 17px;
            font-style: italic;
            color: $dmc-color-7;
        }
    }

    .slick-prev {
        width: 25px;
        height: 25px;
        left: -65px !important;
        opacity: 0.5;
    }

    .slick-next {
        width: 25px;
        height: 25px;
        right: -65px !important;
        opacity: 0.5;
    }

    .slick-dots {
        height: 7px;
        bottom: -9px;

        @media screen and (max-width: $screen-sm-max) {
            bottom: -21px;
        }

        li {
            width: 7px;
            height: 7px;
            margin: 0;
            margin-right: 9px;

            &:last-child {
                margin-right: 0px;
            }

            button {
                width: 7px;
                height: 7px;
                padding: 0;
                font-size: unset;
                line-height: unset;

                &::before {
                    color: $dmc-color-15;
                    opacity: 1;
                    line-height: unset;
                    top: unset;
                    width: unset;
                    height: unset;
                    font-size: 16px;
                    content: "\2022";
                }
            }

            &.slick-active {
                button::before {
                    color: $dmc-color-3;
                    opacity: 1;
                }
            }
        }
    }
}