@import "@styles/variables.scss";

#dmcWidget {
    .notice-button {
        position: relative;
        width: 304px;
        border: 1px solid $dmc-color-3;
        color: $dmc-color-3;
        font-size: 14px;
        line-height: 17px;
        padding: 7px 0 !important;
        background-color: $dmc-color-1;
        outline: none;
        border-radius: 3px;
        font-family: $dmc-font-bold;

        @media screen and (max-width: $screen-sm-max) {
            width: 100%;
            max-width: 304px;
        }

        &:hover {
            border: 1px solid $dmc-color-20;
            background-color: $dmc-color-20;
        }

        &:focus{
            box-shadow: 0 0 2px 2px $dmc-color-focus;
        }

        img {
            margin-right: 10px;
            width: 23px;
            height: 23px;

            &:focus {
                border: dashed 1px $dmc-color-28
            }
        }

        .arrow {
            position: absolute;
            top: calc(50% - 11px);
            right: 13px;
            width: 13px;
            height: 22px;
            transition: 0.2s all ease;
            margin-right: 0px;

            &.expanded {
                transform: rotateZ(-180deg);
            }
        }

        &+.panel {
            width: 100%;
            margin: 0 auto;
            transition: 0.2s all ease;
            height: 0;
            opacity: 0;
            box-shadow: unset;

            >div {
                display: flex;

                @media screen and (max-width: $screen-sm-max) {
                    flex-direction: column;
                }
            }

            &.expanded {
                height: auto;
                opacity: 1;
                padding-top: 29px;
                //margin-bottom: 25px;           

                @media screen and (max-width: $screen-sm-max) {
                    padding-top: 14px;
                }

                .element {
                    display: block;
                }
            }

            .element {
                display: none;
                width: 100%;
                justify-content: space-around;

                &:first-child {
                    margin-right: 5px;

                    @media screen and (max-width: $screen-md-max) {
                        margin-bottom: 20px;
                    }
                }

                button,
                a {
                    width: 100%;
                    max-width: 304px;
                    display: inline-block;
                }

                a:hover {
                    text-decoration: none;
                }

                @media screen and (max-width: $screen-md-max) {
                    flex-direction: column;
                }
            }
        }
    }
}