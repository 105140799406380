@-webkit-keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
.pulsate-bck {
  -webkit-animation: pulsate-bck 0.5s ease-in-out both;
  animation: pulsate-bck 0.5s ease-in-out both;
}