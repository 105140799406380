@import "./variables.scss";
#dmcWidget {
    .select {
        position: relative;
        display: inline-block;
        margin-bottom: 15px;
        width: 345px;

        @media screen and (max-width: $screen-sm-max) {
            width: 100%;
        }

        select {
            display: inline-block;
            width: 100%;
            cursor: pointer;
            padding: 13px 21px 15px;
            padding-right: 43px;
            outline: 0;
            border: 1px solid $dmc-color-13;
            border-radius: 3px;
            background: $dmc-color-1;
            color: $dmc-color-2;
            font-family: $dmc-font-regular;
            font-size: 17px;
            line-height: 26px;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;

            select::-ms-expand {
                display: none;
            }

            option {
                color: $dmc-color-13;
                white-space: pre-wrap;

                &:hover {
                    background: $dmc-color-20 !important;
                }
            }
        }

        select:hover{
            outline: none;
        }

        select:focus{
            outline: none;
            border-color: $dmc-color-focus;
            box-shadow: 0 0 2px 2px $dmc-color-focus;
        }

        .select_arrow {
            display: inline-block;
            position: absolute;
            top: 18px;
            right: 21px;
            pointer-events: none;    

            :first-child{
                    width: 10px; 
                    height: 5px; 
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;                    
                    border-bottom: 5px solid $dmc-color-2;
            }

            :last-child{
                width: 10px; 
                height: 5px; 
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;                
                border-top: 5px solid $dmc-color-2;
                margin-top: 5px;
            }
        }

        select:hover ~ .select_arrow,
        select:focus ~ .select_arrow {
            :first-child{              
                border-bottom-color: $dmc-color-3;
            }

            :last-child{                
                    border-top-color: $dmc-color-3;
            }           
        }

        select:disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        select:disabled ~ .select_arrow {
            border-top-color: $dmc-color-2;
        }
    }
}
