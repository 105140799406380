@import "../../../../../styles/variables.scss";

#dmcWidget {
    .footer-container {
        color: $dmc-color-13;
        font-size: 14px;
        line-height: 17px;
        background-color: $dmc-color-28;
        padding: 19px;
        /* --- style to force footer to fill --- */
        margin: 0 -30px;
        margin-bottom: -15px;

        @media screen and (max-width: $screen-sm-max) {
            padding-top: 0;
            padding-bottom: 15px;
            background-color: unset
        }
    }
}
