@import "@styles/variables.scss";

#dmcWidget {
    .highlight-item {
        box-sizing: border-box;
        border: 1px solid $dmc-color-3;

        &.circle {
            border-radius: 50%;
            height: 50px;
            width: 50px;
        }

        &.square {
            border-radius: 4px;
            box-sizing: border-box;
            height: 136px;
            width: 136px;

            &:hover,
            &:focus {
                background-color: $dmc-color-20;
                border-color: $dmc-color-20
            }

            @media screen and (max-width: $screen-sm-max) {
                height: 112px;
                width: 109px;
            }
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        @supports (-ms-ime-align: auto) {
            justify-content: space-around;
        }

        img{
            width: 50px;
            height: 50px;
        }

        .description {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            text-align: center;
            line-height: 17px;
            width: 95px;
            height: 37px;
            color: $dmc-color-3;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: $screen-sm-max) {
                font-size: 14px;
            }
        }
    }
}