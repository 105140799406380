@import "./variables.scss";

#dmcWidget {
    .DMCWrapper {
        width: 100%;
        display: flex;
        //justify-content: space-around;
        flex-direction: column;
        align-items: center;
        height: 100%;
        border-radius: 6px;
        // margin: 10px;
        position: relative;
        min-height: 400px;

        @media screen and (max-width: $screen-sm-max) {
            margin: 0;
            justify-content: unset;
        }
    }
}
