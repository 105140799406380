@import "../../../../../styles/variables.scss";


#dmcWidget{
    .summary-alert{
        position: relative;
        z-index: 1;
        display: flex;
        width: 100%;
        min-height: 57px;
        padding: 10px 15px 11px;
        background-color: $dmc-color-6;
        font-size: 16px;
        line-height: 19px;
        text-align: left;
        box-shadow: 0 2px 4px rgba(0,0,0,0.24);
    
        @media screen and (max-width: $screen-sm-max){
            font-size: 14px;
            line-height: 17px;
        }
    
        img{
            width: 25px;
            height: 25px;
            margin: auto 0;
            margin-right: 15px;        
        }
    
        .alert-content{            
            margin-top: 8px;


            @media screen and (max-width: $screen-sm-max){
                margin-top: 0;
            }
    
            .alert-link{
                margin-left: 5px;
                color: $dmc-color-3;
                text-decoration: underline $dmc-color-3;
                
                &:hover{
                    cursor: pointer;
                }
    
                &:focus{
                    text-decoration: underline $dmc-color-3;
                }
            }
        }
    }
}