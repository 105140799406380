@import "styles/variables.scss";

.transactionContainer {
    padding-bottom: 0;
    padding-left: 0;
    margin-bottom: 30px;
}

html{
    scroll-behavior: smooth;
}

#dmcWidget {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    scroll-behavior: smooth;
    font-family: $dmc-font-regular;
    color: $dmc-color-2;

    button {
        border: none;
        border-radius: 3px;
        outline-color: none;
        outline: none;
        font-family: $dmc-font-bold !important;
        padding: 15px !important;
        width: 345px;

        &:hover,
        &:focus {
            outline: none !important;
        }

        @media screen and (max-width: $screen-sm-max) {
            width: 100%;
        }
    }

    input {
        border: 1px solid grey;
        border-radius: 3px;
        outline-color: none;
        outline: none;
    }

    button:focus {
        border: none;
        outline: none;
        outline-color: none;
    }

    .loadingEffect {
        filter: grayscale(65%) opacity(75%) blur(1px);
    }

    .loadingContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000000;
        animation: fadein 1s;
        -moz-animation: fadein 1s;
        /* Firefox */
        -webkit-animation: fadein 1s;
        /* Safari and Chrome */
        -o-animation: fadein 1s;

        @media screen and (max-width: $screen-sm-max) {
            position: fixed;
        }

        /* Opera */
        .loader {
            width: 70px;
            height: 70px;
            border: 4px solid transparent;
            border-radius: 50%;
            border-top: 4px solid $dmc-color-3;
            width: 50px;
            height: 50px;
            -webkit-animation: spin 1s linear infinite;
            /* Safari */
            animation: spin 1s linear infinite;
            text-align: center;
            // box-shadow: inset 0px 3px 1px -1px rgba(0, 0, 0, 0.35);
            // -webkit-box-shadow: inset 0px 3px 1px -1px rgba(0, 0, 0, 0.35);
            // -moz-box-shadow: inset 0px 3px 1px -1px rgba(0, 0, 0, 0.35);
        }

        .loaderMessage {
            font-size: 26px;
            line-height: 31px;
            color: $dmc-color-5;
            font-family: $dmc-font-bold;
            text-align: center;
        }
    }

    /*#region Loader - Fade in */
    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @-moz-keyframes fadein {

        /* Firefox */
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadein {

        /* Safari and Chrome */
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @-o-keyframes fadein {

        /* Opera */
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    /*#endregion */
    .title {
        max-width: 668px;
        margin: 0 auto 11px;
        color: $dmc-color-3;
        font-family: $dmc-font-bold;
        font-size: 25px;
        line-height: 31px;

        @media screen and (max-width: $screen-sm-max) {
            font-size: 16px;
            line-height: 21px;
            letter-spacing: -0.2px;
            text-align: center !important;
        }
    }

    .subtitle {
        font-size: 14px;
        line-height: 21px;
        color: $dmc-color-5;
        letter-spacing: -0.1px;
    }

    .question-title {
        font-family: $dmc-font-bold;
        font-size: 25px;
        line-height: 30px;
        color: $dmc-color-3;
        margin: 30px 0;

        @media screen and (max-width: $screen-sm-max) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    input[type="text"] {
        padding: 17px 24px 15px;
        font-size: 19px;
        line-height: 32px;
        width: 458px;
        border: 1px solid $dmc-color-15;
        border-radius: 3px;
        color: $dmc-color-2;

        &:focus {
            box-shadow: 0 0 2px 2px $dmc-color-focus;
        }

        &::placeholder {
            color: $dmc-color-2;
            opacity: 1;
        }

        @media screen and (max-width: $screen-sm-max) {
            padding: 14px 21px;
            width: 100%;
        }
    }

    #welcome-page,
    #questionnaire-page {
        width: 100%;
        max-width: 912px;
        margin: 0 auto;
        margin-top: 30px;

        @media screen and (max-width: $screen-sm-max) {
            width: 100%;
            margin-top: 0;
        }
    }

    .widget-simulator {
        max-width: 917px;
        padding: 0;
        overflow: hidden;
        background: white;
        margin: 30px auto;

        @media screen {
            width: 100%;
        }
    }

    .components-wrapper {
        margin-top: 12px;
        text-align: center;
        border: 1px solid $dmc-color-4;
        border-radius: 5px;

        @media screen and (max-width: $screen-sm-max) {
            margin-top: 0;
            border: none;
        }

        .spacing {
            padding: 20px 20px 0 20px;

            &.resume,
            &.carousel,
            &.welcome {
                padding: 30px;
            }

            &.carousel {
                margin-bottom: 10px;
            }

            @media screen and (max-width: $screen-sm-max) {
                padding: 20px 0 5px;
            }
        }
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .flex-container {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    label {
        font-weight: inherit;
    }
}