@import "./variables.scss";
#dmcWidget {
    /* The radioContainer */
    .radioContainer {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color:transparent;
    }
    /* Hide the browser's default radio button */
    .radioContainer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    /* Create a custom radio button */
    .checkmark {
        position: relative;
        height: 20px;
        width: 20px;
        margin: 3px 0px 0px 3px;
        background-color: #eee;
        border-radius: 50%;
        border: 1px solid $dmc-color-3;
    }
    /* On mouse-over, add a grey background color */
    .radioContainer:hover input ~ .checkmark {
        background-color: $dmc-color-16;
    }
    /* When the radio button is checked, add a blue background */
    .radioContainer input:checked ~ .checkmark {
        background-color: $dmc-color-3;
    }
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    /* Show the indicator (dot/circle) when checked */
    .radioContainer input:checked ~ .checkmark:after {
        display: block;
    }
    /* Style the indicator (dot/circle) */
    .radioContainer .checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $dmc-color-3;
    }
    }