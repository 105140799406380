@import "@styles/variables.scss";

#dmcWidget {
    .contextual-note-container {
        text-align: left;

        .note-title {
            font-size: 14px;
            font-weight: bold;
            color: $dmc-color-30;
            letter-spacing: 0;
            line-height: 17px;
        }

        .note-subtitle {
            font-size: 14px;
            font-style: italic;
            line-height: 17px;
            color: #000;
        }

        .note-panel {
            .notes-title {
                color: $dmc-color-3;
                font-size: 16px;
                font-weight: bold;
            }

            .notes-content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .note-text {
                    box-sizing: border-box;
                    border: 1px solid #CCC;

                    width: 100%;
                    //max-width: 700px;
                    height: 100%;
                    max-height: 60px;
                    padding: 5px 10px;
                    margin-right: 10px;

                    resize: none;

                    &:focus {
                        outline: none;
                    }
                }

                .note-text-readonly {
                    width: 100%;
                    max-width: 700px;
                    height: 100%;
                    max-height: 60px;
                    padding: 5px 10px;

                    white-space: break-spaces;
                    overflow-y: auto;

                    padding: 5px 10px;

                    font-size: 14px;
                }

                .note-button {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: bold;
                    color: $dmc-color-3;
                    letter-spacing: 0;
                    text-align: center;
                    padding: 7px 0 !important;

                    width: 100%;
                    max-width: 90px;

                    height: 100%;
                    max-height: 34px;

                    &.note-button-icon {
                        width: 100%;
                        max-width: 150px;
                        max-height: 100%;
                    }

                    &:hover {
                        color: $dmc-color-1;
                    }
                }
            }
        }
    }
}