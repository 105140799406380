@import "./variables.scss";

#dmcWidget {
    .Context {
        width: 300px;
        height: auto;
        position: fixed;
        bottom: 0;
        right: 0;
        background-color: lightgray;
        font-size: 10px;
        padding: 10px;

        @media screen and (max-width: $screen-md-max) {
            display:none;
        }

        h4 {
            font-size: 12px;
            font-weight: bold;
        }
    }
}
